/* dashboard sections */

.inner-box.portfolio.dashboard-port {
 min-height: 29.875rem;
 width: 25rem;
 overflow: hidden;
 /* margin: 0em 1.25rem -1.25rem; */
 margin: 0rem 1.25rem -1.25rem;
 background-color: $clr-bg-portfolio;
 border: 0rem solid $clr-bg-portfolio;
 padding: 0;
 box-shadow: $clr-box-shadow;
 padding-top: 0.625rem;
}

.inner-box.portfolio.dashboard-port.subsc-card {
 min-height: 21.875rem;
}

.dashboard-border {
 border-top: 0.1875rem solid $clr-green;
 margin: -0.625rem 0 0.9375rem;
}

p.port-desc.advisor-name.dashboard {
 margin-left: 0.625rem;
 text-transform: capitalize;
 font-family: $font-family-opensans;
 font-size: 1rem;
 font-weight: $font-semibold;
 margin-top: 0.125rem;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}

.portfolio-border-top p.port-desc.dashboard {
 margin-left: -1.1875rem;
}

.portfolio-border-top p.port-desc.dashboard.portfolio {
 margin-left: -0.9375rem;
}

p.portfolio-date.dashboard.amountinvest {
 margin-left: 0rem !important;
}

p.portfolio-date.dashboard {
 margin-left: -3.4375rem !important;
}

p.portfolio-heading.dashboard-msg {
 color: $clr-green;
 font-size: 1.0625rem;
 font-weight: $font-semibold;
}

button.theme-btn.btn-style-one.get-started.download-agreement {
 background-color: transparent;
 border: 0.0625rem solid $clr-text-one;
 color: $clr-green;
}

.portfolio-details.dashboard {
 height: 29.875rem;
 border-radius: 0.3125rem;
}

p.autorenew {
 color: $clr-green;
 font-weight: $font-bold;
 font-family: $font-family-opensans;
 font-size: 1.0625rem;
 line-height: 1.4375rem;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.dashboard-top {
 margin-top: 7em;
 flex-wrap: wrap !important;
 /* display: flex; */
}

main.dashboard-scroll.MuiBox-root {
 max-height: 40.3125rem;
 overflow-y: scroll;
 width: 100%;
 min-height: 40.3125rem;
}

/* Common styles */
.scroll-container {
 // overflow-y: auto;
 max-height: 30.3125rem;
 overflow-y: scroll;
 width: 100%;
 //min-height: 50.3125rem;
}

.scroll-container.horizontal-scroll {
 // overflow-x: auto;
 overflow-x: scroll;
 overflow-y: hidden;
 white-space: nowrap;
}

.scroll-container .scroll-item {
 display: inline-block;
 white-space: normal; /* For horizontal scrolling items */
}

/* Media queries */
@media screen and (max-width: 768px) {
 .scroll-container {
  overflow-y: hidden;
  // overflow-x: auto;
  overflow-x: scroll;
  max-height: 36rem;
 }
}

.loader {
 padding: 10px;
}
.loader.dash-loading {
 padding: 10px;
 text-align: center;
 font-size: 18px;
 font-weight: 700;
}

.line-break {
 line-break: anywhere;
 -webkit-line-clamp: unset !important;
 overflow: visible !important;
}
.inner-box.portfolio.orderbook {
 background-color: $clr-bg-portfolio;
 border: 0rem solid $clr-bg-portfolio;
 padding: 0;
 box-shadow: $clr-box-shadow;
 padding: 1.25rem 1.875rem 0.9375rem;
 margin: 0rem 2.5rem 1.5625rem;
 width: auto;
 min-height: 7.8125rem;
 height: auto;
}
.order-headborder {
 border-bottom: 0.1rem solid $clr-text-eight;
}
.order-subborder {
 border-bottom: 0.046875rem solid $clr-text-eight;
}
.order-subhead {
 color: $clr-pink;
 font-weight: $font-semibold;
 font-family: $font-family-opensans;
 font-size: 1rem;
 line-height: 1.375rem;
 margin: 0.625rem 0;
 padding: 0 0.625rem 0 0rem;
}
.order-subborder:last-child {
 border-bottom: none;
}
hr.MuiDivider-root.MuiDivider-fullWidth {
 width: 100%;
 border-bottom: 0.0625rem solid $clr-text-eight;
}
.dashboard-section {
 background-color: $clr-bg-main;
 margin-top: -3.75rem;
 /* min-height: 100vh; */
}

p.note {
 font-size: 0.875rem;
 font-family: $font-family-opensans;
 font-weight: $font-medium;
 text-align: center;
 line-height: 1.5rem;
 color: $clr-red;
}
.refreshicon {
 margin: 1.4375rem 0.3125rem 0 !important;
 cursor: pointer;
 color: $clr-green;
}
.refreshicon:hover {
 color: $clr-blue;
}
.inner-box.portfolio.orderbook.detail-popup {
 background-color: $clr-white;
 border: 0rem solid $clr-white;
 padding: 0;
 box-shadow: $clr-box-shadow;
 padding: 1.25rem 1.875rem 0.9375rem;
 margin: 0rem 2.5rem -2.5rem;
 width: auto;
 max-height: 5.625rem;
 min-height: 19.375rem;
 height: 5.625rem;
 overflow-y: scroll;
}
.order-subhead.detail-popup {
 color: #5d5d5d;
 font-weight: $font-medium;
 font-size: 0.9375rem;
}
p.portfolio-heading.detail-popup {
 color: #2c2c2c;
 font-weight: $font-semibold;
}
.risk-ques-border.detail-popup {
 width: 11%;
 margin-top: 0rem;
}
h2.risk-profile-ques.detail-popup {
 margin-top: -3.625rem;
}
.order-subborder.detail-popup {
 border-bottom: 0.046875rem solid #b8b8b8;
}
.order-headborder.detail-popup {
 border-bottom: 0.1rem solid #808080;
}
p.account-value.sell {
 left: 2.8125rem;
 top: -1.5625rem;
}
p.account-value.buy {
 left: 2.8125rem;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-9.MuiGrid-grid-md-9.signup-container.d-flex.margin-auto.investing-grid.sell-mt {
 margin-top: -1.5625rem !important;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4.MuiGrid-grid-md-4.l-float.sell-ml {
 margin-left: -3.1875rem;
}
svg.delete-rebalance {
 margin-top: 0.625rem;
 color: $clr-bg-portfolio;
 cursor: pointer;
}
.sell-height {
 max-height: 8.375rem;
 overflow-y: scroll;
}
p.account-bal.mb-acbal {
 margin-left: 2.25rem;
 text-align: left;
}
.btn-w160 {
 width: 9.25rem;
}
h2.risk-profile-ques.detail-headp {
 margin: -3.4375rem 0 1.25rem;
}
.login-broker {
 margin: 2.5rem 0 !important;
}

img.api-error {
 width: 20%;
 margin-top: 5em;
}
h2.apierror {
 color: $clr-black;
 font-weight: $font-semibold;
 font-family: $font-family-opensans;
 font-size: 1.5rem;
 text-align: center;
 margin: 0em auto;
 font-style: italic;
 text-transform: capitalize;
}

/* dashboard sections */
button.theme-btn.btn-style-one.get-started.btn-w160.btn-dash {
 width: 136px;
 min-height: 33px;
 height: 33px;
 line-height: 1;
 min-width: 135px;
 font-size: 15px !important;
}

button.theme-btn.btn-style-one.get-started.btn-w160.btn-dash.clr-gray {
 background-color: #6b6b6b !important;
}

.ml-0 {
 margin-left: 0 !important;
}
.dash-btn-relative {
 position: relative;
}
.dash-btn-center {
 margin: 0;
 position: absolute;
 top: 50%;
 left: 73%;
 -ms-transform: translateY(-50%);
 transform: translateY(-50%);
}
.dash-order-width {
 width: 60% !important;
}
.mobile-view-loading {
 display: none;
}
.desktop-view-loading {
 display: block;
}
.w-45 {
 width: 45px;
}

.error-stackbar-container {
  display: flex;
  justify-content: flex-start; 
  width: 100%; 
  padding: 1em 1em 1em 4.5em !important; 
  box-sizing: border-box; 
}

.error-stackbar {
  width: 100%; 
  padding: 1px !important;
  box-sizing: border-box; 
}