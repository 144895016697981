li a.theme-btn.btn-style-one.get-started {
	text-transform: none;
	border-radius: 1.875rem;
	background-color: $clr-green;
	color: $clr-white;
	width: auto;
	padding: 0;
	display: inline-grid;
	font-weight: $font-semibold;
	font-size: 1.25rem;
	font-family: $font-family-opensans;
	top: 1rem;
}

li a.theme-btn.btn-style-one.get-started .txt {
	padding: 0.5rem 1.25rem;
}

a.theme-btn.btn-style-one.get-started {
	text-transform: none;
	border-radius: 1.875rem;
	background-color: $clr-green;
	color: $clr-white;
	width: auto;
	padding: 0.5rem 1.25rem;
	display: inline-grid;
	font-weight: $font-semibold;
	text-align: center;
	font-size: 1.10rem;
	font-family: $font-family-opensans;
	line-height: 1.4375rem;
	text-decoration: none;
}

a.theme-btn.btn-style-one.get-started:hover {
	color: $clr-green;
}

/*Btn Style One*/

.btn-style-one {
	position: relative;
	line-height: 1;
	overflow: hidden;
	text-transform: none;
	border-radius: 1.875rem;
	background-color: $clr-green;
	color: $clr-white;
	width: auto;
	padding: 0.5625rem 1.25rem;
	display: inline-grid;
	font-weight: $font-semibold;
	font-size: 1.10rem;
	font-family: $font-family-opensans;
	cursor: pointer;
}

.btn-style-one:before {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	background-color: $btn-color-one;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
	-webkit-transform: scale(0.2, 1);
	transform: scale(0.2, 1);
}

.btn-style-one i {
	position: relative;
	top: 0.0625rem;
	margin-left: 0.25rem;
}

.btn-style-one .txt {
	position: relative;
	z-index: 1;
}

.btn-style-one:hover::before {
	opacity: 1;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.btn-style-one:hover {
	color: $btn-color-two;
}

button.theme-btn.btn-style-one.get-started {
	background-color: $clr-green;
	box-shadow: none !important;
}

.btn {
	border-radius: 1.5625rem !important;
	transition: none !important;
	box-shadow: none !important;
	font-family: $font-family-opensans;
	font-size: 1.0625rem !important;
	font-weight: 700 !important;
	height: 2.625rem !important;
	padding: 0.5rem 1.25rem;
	margin: 0rem 0.9375rem 1.25rem;
}

.btnjwt {
	border-radius: 1.5625rem !important;
	transition: none !important;
	box-shadow: none !important;
	font-family: "Open Sans", sans-serif;
	font-size: 1.3rem !important;
	font-weight: 600 !important;
	height: 2.625rem !important;
	padding: 0.5rem 1.25rem;
	margin: 3rem -0.0625rem 1.25rem;
	color: #3A67E1;
}

.btn:disabled,
.btn:disabled:hover,
.btn:disabled:active,
.btn:disabled:hover:active::before {
	background-color: $clr-bg-button-disabled;
	box-shadow: unset !important;
}

.btn-style-one.btn-style-one2 {
	position: relative;
	line-height: 1.5;
	overflow: hidden;
	text-transform: none;
	border-radius: 4px;
	background-color: $clr-green;
	color: #ffffff;
	width: auto;
	padding: 0.5625rem 1.25rem;
	display: inline;
	font-weight: 600;
	font-size: 1rem;
	font-family: "Poppins", sans-serif;
	cursor: pointer;
	width: 100%;
	text-align: center;
	height: 40px;
}

.theme-btn.btn-style-one.btn-style-one2.get-started.mb-factbtn {
	font-size: 14px;
}
button.btn.btn-1.hover-filled-slide-right.btn-mobile-front.mb-20.accagrement::before {
    background-color: $btn-color-two;
}
button.btn.btn-1.hover-filled-slide-right.btn-mobile-front.mb-20.accagrement:hover {
    background-color: $btn-color-one;
}
button.btn.btn-1.hover-filled-slide-right.btn-mobile-front.mb-20.accagrement span:hover{
	color: $btn-color-two;
}
button.btn.btn-1.hover-filled-slide-right.digiofail::before {
    background-color:  $btn-color-two;
}
.btn-style-one.btn-style-one2:hover{
	color: $btn-color-two;
}