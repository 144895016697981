/* portfolio details */
.portnamegradauto {
	min-height: 2rem;
	overflow: hidden;
}
.portdetailsarrow {
	width: 2.5%;
	margin-top: 0.4375rem;
}
.portheading-grid {
	display: grid;
	max-width: 85% !important;
}
.gradient-grid {
	display: grid;
	max-width: 100% !important;
	height: 2rem;
}
p.high-risk-details span {
	line-height: 2.4;
}
p.medium-risk-details span {
	line-height: 2.4;
}
p.low-risk-details span {
	line-height: 2.4;
}
.d-flex-portinfo {
	display: flex;
	width: 31.25rem;
}
p.portfoliodetails-heading {
	font-size: 1.8125rem;
	color: $clr-green;
	font-weight: $font-bold;
	font-family: $font-family-montserrat;
	margin: 0rem 1.25rem 0 0;
	line-height: 1.75rem;
	text-transform: capitalize;
}

.mt-10 {
	margin-top: 10em !important;
}

.mt-12 {
	margin-top: 12.5em !important;
}
.high-risk-details {
	height: 1.875rem;
	background-image: $clr-hrd;
	background-size: 120% 120%;
    -webkit-animation: AnimationName 10s ease infinite;
    -moz-animation: AnimationName 10s ease infinite;
    -o-animation: AnimationName 10s ease infinite;
    animation: AnimationName 10s ease infinite;
}

.medium-risk-details {
	height: 1.875rem;
	background: $clr-mrd;
	background-size: 120% 120%;
    -webkit-animation: AnimationName 10s ease infinite;
    -moz-animation: AnimationName 10s ease infinite;
    -o-animation: AnimationName 10s ease infinite;
    animation: AnimationName 10s ease infinite;
}

.low-risk-details {
	height: 1.875rem;
	background: $clr-lrd;
	background-size: 120% 120%;
    -webkit-animation: AnimationName 10s ease infinite;
    -moz-animation: AnimationName 10s ease infinite;
    -o-animation: AnimationName 10s ease infinite;
    animation: AnimationName 10s ease infinite;
}
.portdetail-gradients {
	background: $clr-portfoliodetails;
	padding: 1.25rem 0 1.25rem 0rem;
	margin: 1.25rem 0rem 0 1.875rem !important;
}
.text-image-details {
	color: $clr-white;
	text-transform: uppercase;
	font-family: $font-family-montserrat;
	font-size: 0.875rem;
	font-weight: $font-semibold;
	margin-left: 0.625rem;
	line-height: 2.3 !important;
}
p.port-desc.port-desc-details {
	font-family: $font-family-opensans;
	font-size: 1rem;
	font-weight: $font-medium;
}
p.portdetails-date {
	margin-left: 0.6875rem !important;
}
p.nostock {
	margin-left: 0rem !important;
}
.portfolio-border-top p.port-desc-details {
	line-height: 1.375rem;
	margin-left: 1.625rem;
	font-family: $font-family-opensans;
	font-size: 1rem;
	font-weight: $font-medium;
}
p.port-desc.port-desc-details.portdetails-investstyle {
	margin-left: -0.9375rem;
	overflow: visible;
	display: inline-block;
	-webkit-line-clamp: unset;
	-webkit-box-orient: unset;
	word-break: break-word;
}
.pleft-20 {
	padding-left: 1.25rem;
}
.pleft-10 {
	padding-left: 0.625rem;
}
p.port-desc.port-desc-details.port-advisor-border.lineheight {
	text-transform: capitalize;
	line-height: 2 !important;
}
.lineheight {
	line-height: 1.4 !important;
}
.port-advisor-border {
	border-bottom: 0.0625rem solid $clr-black;
	padding: 0 0 0.3125rem;
	margin: 0 0.9375rem 0.9375rem 0 !important;
}
p.rightarrow {
	margin: 1rem 0rem 0rem 0.3125rem;
}
.port-graph-bottom {
	padding-bottom: 3.125rem !important;
}
.bg-gray {
	background-color: $clr-bg-gray;
	padding-bottom: 3.75rem;
}
p.sswtext {
	font-size: 0.875rem;
	color: $clr-text-five;
	font-family: $font-family-opensans;
	font-weight: $font-semibold;
}


h4.portdetails-heading {
	text-transform: uppercase;
	font-size: 1.125rem;
	text-align: left;
	color: $clr-text-three;
	font-weight: $font-bold;
	font-family: $font-family-montserrat;
	line-height: 1.375rem;
}

h4.portdetails-question {
	font-size: 1.125rem;
	text-align: left;
	color: $clr-text-three;
	font-weight: $font-bold;
	margin-top: 0.625rem;
	font-family: $font-family-opensans;
	line-height: 1.8125rem;
	margin-bottom: 0.5rem;
}

p.portdetails-answer {
	text-align: left;
	color: $clr-text-five;
	font-size: 0.9375rem;
	width: 70%;
	font-family: $font-family-opensans;
	line-height: 1.5625rem;
	font-weight: $font-regular;
	margin-bottom: 1.875rem;
}

.border-right {
	border-right: 0.125rem solid #505050;
}

.pl-40 {
	padding-left: 2.5rem;
}

.graph-img {
	padding-left: 2.1875rem;
}

.borkerinputbox {
    margin-top: 20px !important;
    margin-left: 10px !important;
}
.borkerinputbox input{
    padding: 4.5px;
}
/* portfolio details */