/* footer */

.main-footer {
 /* position: relative; */
 background-color: $footer-bg;
 z-index: 1;
 position: relative;
 /* left: 0rem;
    top: 0rem;
    right: 0rem;
    bottom: 0rem; */
}

.main-footer.style-two {
 border-top: 0.0625rem solid $clr-text-two;
}

.main-footer .footer-widget {
	position: relative;
	margin-bottom: 1.875rem;
	margin-top: 1.25rem;
}

.main-footer .widgets-section {
 position: relative;
 padding: 1.25rem 0rem 0rem;
}

.main-footer .footer-widget h5 {
	position: relative;
	font-weight: $font-bold;
	color: $clr-white;
	line-height: 1.75rem;
	margin-top: 1.5625rem;
	margin-bottom: 1.5625rem;
	text-transform: capitalize;
	font-size: 1rem;
	font-family: $font-family-opensans;
}

.main-footer .logo-widget {
 position: relative;
}

.main-footer .logo-widget .logo {
 position: relative;
 margin-bottom: 0rem;
}

.main-footer .logo-widget .call {
 position: relative;
 color: $clr-text-nine;
 font-size: 0.875rem;
 font-weight: $font-regular;
 line-height: 1.8em;
 text-transform: uppercase;
}

.main-footer .logo-widget .call .phone {
 position: relative;
 color: $clr-black;
 font-size: 1.5rem;
 display: block;
 margin-top: 0.9375rem;
 margin-bottom: 0.4375rem;
 transition: all 500ms ease;
 -moz-transition: all 500ms ease;
 -webkit-transition: all 500ms ease;
 -ms-transition: all 500ms ease;
 -o-transition: all 500ms ease;
}

.main-footer .logo-widget .call .email {
 position: relative;
 color: $clr-black;
 font-size: 1rem;
 display: block;
 margin-top: 0rem;
 text-transform: capitalize;
 transition: all 500ms ease;
 -moz-transition: all 500ms ease;
 -webkit-transition: all 500ms ease;
 -ms-transition: all 500ms ease;
 -o-transition: all 500ms ease;
}

.main-footer .logo-widget .call .phone:hover,
.main-footer .logo-widget .call .email:hover {
 color: $clr-hyperlink;
}

/* List Link */

.list-link {
 position: relative;
 list-style: none;
}

.list-link li {
	position: relative;
	margin-bottom: 0.5rem;
}

.list-link li a {
	position: relative;
	color: $clr-white;
	font-size: 0.875rem;
	font-weight: $font-regular;
	font-family: $font-family-opensans;
	line-height: 1.75rem;
	text-decoration: none;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	transition: all 300ms ease;
}

ul.list-link {
	padding-left: 0;
}

.list-link li a:hover {
	color: $link-hover;
}

.main-footer .newsletter-widget .text {
	position: relative;
	color: $clr-white;
	font-size: 0.8125rem;
	line-height: 1.125rem;
	margin-bottom: 0.9375rem;
	margin-top: 1.25rem;
	font-family: $font-family-opensans;
	font-weight: $font-regular;
	text-align: justify;
	width: 86%;
}

.s-ft {
	position: relative;
	color: #FFFFFF;
	font-size: 12px;
	line-height: 1.125rem;
	margin-bottom: 0.9375rem;
	margin-top: 1.25rem;
	font-family: "Montserrat", sans-serif;
	font-weight: 400;
}

.mobile-footer {
	display: none;
}

p.copyright {
	text-align: center;
	padding: 0.625rem 10px;
	color: $clr-white;
	font-size: 0.8rem;
	font-weight: $font-regular;
	font-family: $font-family-opensans;
	line-height: 1.75rem;
	border-top: 0.0625rem solid $clr-border-three;
}

a.icofont-ui-call {
	border: 0.0625rem solid $clr-text-one;
	border-radius: 3.125rem;
	padding: 0.625rem;
	margin-right: 0.9375rem;
}

a.icofont-envelope {
	border: 0.0625rem solid $clr-text-one;
	border-radius: 3.125rem;
	padding: 0.625rem;
	margin-right: 0.9375rem;
}

a.icofont-location-pin {
	border: 0.0625rem solid #F3F3F3;
	border-radius: 3.125rem;
	padding: 0.6rem;
	margin-right: 0.9375rem;
	font-size: 1rem !important;
}

.ok-space {
	margin-top: 9px !important;
}